import store from './store'

export const url_brand = store.getOEMUrl() + '/app/';
export const BRAND_IMAGE_FAVICON = store.getOEMUrl() + "/admin/favicon.ico";
export const BRAND_BACKGROUND_COLOR = store.getOemBackgroundColor();
export const BRAND_IMAGES = {
    // images
    "add": url_brand + "add.png",
    "back": url_brand + "back.png",
    "book": url_brand + "book.png",
    "box_green": url_brand + "box_green.png",
    "box_red": url_brand + "box_red.png",
    "box_yellow": url_brand + "box_yellow.png",
    "btnbg_color_blue": url_brand + "btnbg_color_blue.png", //?
    "btnbg_color_yellow": url_brand + "btnbg_color_yellow.png", //?
    "classrecod_bg": url_brand + "classrecod_bg.jpg",
    "clock": url_brand + "clock.gif",
    "cutbox_bg": url_brand + "cutbox_bg.jpg",
    "cutbox_bg_lixiao": url_brand + "cutbox_bg_lixiao.jpg",
    "detail": url_brand + "detail.png",
    "focus": url_brand + "focus.png", //
    "forget": url_brand + "forget.png", //
    "ico_book": url_brand + "ico_book.png", //
    "ico_fk": url_brand + "ico_fk.gif", //
    "ico_kk": url_brand + "ico_kk.gif", //
    "ico_nck": url_brand + "ico_nck.gif",
    "unDifficult": url_brand + "unDifficult.png",
    "difficult": url_brand + "star.png",
    "ico_pm": url_brand + "ico_pm.png",
    "ico_shop": url_brand + "ico_shop.gif", //
    "ico_study": url_brand + "ico_study.png", //
    "ico_tjm": url_brand + "ico_tjm.png", //
    "ico_xk": url_brand + "ico_xk.png", //
    "ico_xx": url_brand + "ico_xx.png", //
    "ico_yh": url_brand + "ico_yh.gif", //
    "level_01": url_brand + "level_01.png",
    "level_02": url_brand + "level_02.png", //
    "level_03": url_brand + "level_03.png", //
    "login_bottom": url_brand + "login_bottom.jpg", //
    "login_top": url_brand + "login_top.jpg",
    "login_top_lixiao": url_brand + "login_top_lixiao.jpg",
    "mix_bg": url_brand + "mix_bg.png",
    "mix_bg_lixiao": url_brand + "mix_bg_lixiao.jpg",
    "nothing": url_brand + "nothing.png",
    "notice_bg": url_brand + "notice_bg.jpg",
    "order_bg": url_brand + "order_bg.jpg", //
    "password_bg": url_brand + "password_bg.jpg",
    "performance": url_brand + "performance.png",
    "personal_ico": url_brand + "personal_ico.png",
    "personal_ico_01": url_brand + "personal_ico_01.png",
    "personal_ico_02": url_brand + "personal_ico_02.png",
    "personal_ico_03": url_brand + "personal_ico_03.png",
    "personal_ico_04": url_brand + "personal_ico_04.png",
    "personal_ico_05": url_brand + "personal_ico_05.png",
    "personal_ico_06": url_brand + "personal_ico_06.png",
    "personal_ico_07": url_brand + "personal_ico_07.png",
    "personal_ico_08": url_brand + "personal_ico_08.png",
    "personal_ico_09": url_brand + "personal_ico_09.png",
    "personal_ico_10": url_brand + "personal_ico_10.png",
    "personal_ico_11": url_brand + "personal_ico_11.png",
    "personal_ico_12": url_brand + "personal_ico_12.png",
    "personal_ico_13": url_brand + "personal_ico_13.png",
    "play": url_brand + "play.png",
    "print": url_brand + "print.png",
    "feedback": url_brand + "feedback.png",
    "quit": url_brand + "quit.png",
    "ranking_title_pic": url_brand + "ranking_title_pic.gif", //
    "review_bg_level": url_brand + "review_bg_level.png",
    "review_bottom_bg": url_brand + "review_bottom_bg.jpg",
    "review_top_bg": url_brand + "review_top_bg.png",
    "review_middle_bg": url_brand + "review_middle_bg.png",
    "review_btn_startnew": url_brand + "review_btn_startnew.png",
    "s-err": url_brand + "s-err.gif", //
    "s-right": url_brand + "s-right.gif", //
    "select_class_type": url_brand + "select_class_type.png", //
    "select_class_type1": url_brand + "select_class_type1.png",
    "select_class_type2": url_brand + "select_class_type2.png",
    "select_class_type3": url_brand + "select_class_type3.png",
    "select_class_type11": url_brand + "select_class_type11.png",
    "star": url_brand + "star.png", //
    "step02_01ico": url_brand + "step02_01ico.png", //
    "step02_02ico": url_brand + "step02_02ico.png", //
    "step02_03ico": url_brand + "step02_03ico.png", //
    "step02_04ico": url_brand + "step02_04ico.png", //
    "stepbg": url_brand + "stepbg.jpg",
    "student_list_bg": url_brand + "student_list_bg.jpg",
    "SuggestionBox": url_brand + "SuggestionBox.png", //
    "user1-160x160": url_brand + "user1-160x160.png",
    "user2-160x160": url_brand + "user2-160x160.png",
    "t-user1-160x160": url_brand + "t-user1-160x160.png",
    "t-user2-160x160": url_brand + "t-user2-160x160.png",
    "user-none": url_brand + "user-none.png",
    "vocabulary_test_result": url_brand + "vocabulary_test_result.png",
    "vocabulary_test_result_01": url_brand + "vocabulary_test_result_01.png",
    "vocabulary_test_result_01_bak": url_brand + "vocabulary_test_result_01_bak.png", //
    "wordtest_bg": url_brand + "wordtest_bg.jpg",
    "vocabulary_topbg": url_brand + "vocabulary_topbg.png",
    
    //2024.11.08 刘琪添加
    "btn_study_01": url_brand + "btn_study_01.png",
    "btn_study_02": url_brand + "btn_study_02.png",
    "icon_book_green": url_brand + "icon_book_green.png",
    "icon_book_red": url_brand + "icon_book_red.png",
    "icon_close": url_brand + "icon_close.png",
    "icon_commission": url_brand + "icon_commission.png",
    "icon_favorites": url_brand + "icon_favorites.png",
    "icon_feedback": url_brand + "icon_feedback.png",
    "icon_grammar": url_brand + "icon_grammar.png",
    "icon_notice": url_brand + "icon_notice.png",
    "icon_password": url_brand + "icon_password.png",
    "icon_persoalinfo": url_brand + "icon_persoalinfo.png",
    "icon_read": url_brand + "icon_read.png",
    "icon_referral": url_brand + "icon_referral.png",
    "icon_rest": url_brand + "icon_rest.png",
    "icon_sign": url_brand + "icon_sign.png",
    "icon_testrecord": url_brand + "icon_testrecord.png",
    "icon_update": url_brand + "icon_update.png",
    "icon_version": url_brand + "icon_version.png",
    "icon_word": url_brand + "icon_word.png",
    "icon_wordtest": url_brand + "icon_wordtest.png",
    "icon_wordtest_p": url_brand + "icon_wordtest_p.png",
    "btn_cut_g": url_brand + "btn_cut_g.png",
    "btn_cut_y": url_brand + "btn_cut_y.png",
    "btn_cut_r": url_brand + "btn_cut_r.png",
    "personalbg": url_brand + "personalbg.png",
    "okbg": url_brand + "okbg.png",
    "ico_play": url_brand + "ico_play.png",
    "welcome": url_brand + "welcome.png",

    // assets
    "shapes": url_brand + "shapes.svg", //

    // icon
    "favicon": store.getOEMUrl() + "/admin/favicon.ico",
    "icon": url_brand + "icon.png",

    // images/reading
    "readingBook": url_brand + "reading/book.png",
    "readingEnd": url_brand + "reading/end.png",
    "readingFlag": url_brand + "reading/flag.png", //
    "readingFlagOff": url_brand + "reading/flag-off.png",
    "readingFlagOn": url_brand + "reading/flag-on.png",
    "readingFlagEnd": url_brand + "reading/flag-red.png",//
    "readingHide": url_brand + "reading/hide.png", //
    "readingLocked": url_brand + "reading/locked.png",
    "readingPlay": url_brand + "reading/play.png", //
    "readingPrepareBlue": url_brand + "reading/prepare-blue.png", //
    "readingPrepareRed": url_brand + "reading/prepare-red.png",
    "readingRecord": url_brand + "reading/record.png", //
    "readingReplay": url_brand + "reading/replay.png", //
    "readingShow": url_brand + "reading/show.png", //
    "readingTestOff": url_brand + "reading/test-off.png", //
    "readingTestOn": url_brand + "reading/test-on.png", //
    "readingTranslate2Off": url_brand + "reading/translate2-off.png",
    "readingTranslate2On": url_brand + "reading/translate2-on.png",
    "readingTranslateOff": url_brand + "reading/translate-off.png",
    "readingTranslateOn": url_brand + "reading/translate-on.png",
    "readingUnlock": url_brand + "reading/unlock.png",
    "readingWord": url_brand + "reading/word.png",
    "audio_stop" : url_brand + "audio_stop.png",
    "voice_play" : url_brand + "voice_play.gif",
    "dictionary" : url_brand + "dictionary.jpg",
    "grammar" : url_brand + "grammar.jpg",
    "shortArticle" : url_brand + "shortArticle.jpg",
    "dialogue" : url_brand + "dialogue.jpg",
    "voiceConversation" : url_brand + "voiceConversation.jpg",
    "ai_sentenceTraining" : url_brand + "ai_sentenceTraining.jpg",
    "ai_comprehension" : url_brand + "ai_comprehension.jpg",
    "ai_articles" : url_brand + "ai_articles.png",
    "commission" : url_brand + "commission.svg",
    "title_decorator" : url_brand + "commissionVerify/title_decorator.svg",
    "title_line" : url_brand + "commissionVerify/title_line.svg",
    "calendar_icon" : url_brand + "commissionVerify/calendar_icon.svg",
    "shield" : url_brand + "commissionVerify/shield.svg",
    "security" : url_brand + "commissionVerify/security.svg",
    "search_icon" : url_brand + "commissionVerify/search_icon.svg",
    "status_icon1" : url_brand + "commissionVerify/status_icon1.svg",
    "status_icon2" : url_brand + "commissionVerify/status_icon2.svg",
    "status_icon3" : url_brand + "commissionVerify/status_icon3.svg",
    "status_icon4" : url_brand + "commissionVerify/status_icon4.svg",

    //2025.03.10 刘孟秋添加
    "icon_30_minutes" : url_brand + "30_minutes.png",
    "icon_60_minutes" : url_brand + "60_minutes.png",
}

export function getBrandImageUrl(name: string) {
    return `${store.getOEMUrl()}/app/${name}`;
}